
import Vue, { PropType } from 'vue';
import { PaymentInfo } from '@/api/gabia-proxy/types';
import { ServiceAdmin } from '@/api/service/types';
import { NimsGroup, NimsEquipment } from '@/api/infra-renew/types';
import { NIMS_EQUIP_DEFAULT } from '@/assets/data/nims/constants';
import BillLayout from '@/layouts/BillLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import InfoModal from '@/components/common/InfoModal/InfoModal.vue';
import SkeletonGradient from '@/components/loading/SkeletonGradient.vue';
export default Vue.extend({
  name: 'NimsGroupSelectTechnicalTemplate',
  components: { BillLayout, FormLayout, ColumnLayout, TableLayout, InfoModal, SkeletonGradient },
  props: {
    groupInfoList: {
      type: Array as PropType<NimsGroup[]>,
      default: () => [],
    },
    billAdmin: {
      type: Object as PropType<ServiceAdmin>,
      default: () => {
        return { name: '', email: '', mobile: '', phone: '' };
      },
    },
    equipmentList: {
      type: Array as PropType<NimsEquipment[]>,
      default: () => [],
    },
    serviceAdmin: {
      type: Object as PropType<ServiceAdmin>,
      default: () => {
        return { name: '', email: '', mobile: '', phone: '' };
      },
    },
    currentEquipment: {
      type: Object as PropType<NimsEquipment>,
      default: () => {
        return NIMS_EQUIP_DEFAULT;
      },
    },
    paymentInfo: {
      type: Object as PropType<PaymentInfo>,
      default: () => {
        return {
          alias: '',
          type_name: '',
          type_code: '',
          pay_method: '',
          card_no: '',
          company_name: '',
          company_name_full: '',
          pay_method_name: '',
        };
      },
    },
    mainGroupNo: {
      type: Number,
      default: 0,
    },
    currentEquipmentSeqNo: {
      type: Number,
      default: 0,
    },
    hasEquipment: {
      type: String,
      default: 'N',
    },
  },
  data() {
    return {
      formTitle,
      tmpMainNo: '',
      tmpEquipNo: '',
      package_id: '',
      goods_id: '',
      btnHeight: '35px',
      infoText: '140px',
    };
  },
  computed: {
    mainGroup(): NimsGroup {
      return this.groupInfoList.filter((item) => Number(item.seqno) === this.mainGroupNo)[0] || {};
    },
    isDataLoaded(): boolean {
      return this.mainGroupNo !== 0;
    },
    mainGroupChargeType(): string {
      return this.mainGroup.service.pay_type.code === 'PA' ? '정기결제' : '일반결제';
    },
  },
  watch: {
    mainGroupNo(newValue) {
      this.tmpMainNo = String(newValue);
    },
  },
  mounted() {
    this.package_id = this.$route.query.package as string;
    this.goods_id = this.$route.query.goods_id as string;
  },
  methods: {
    getDate(date: string) {
      return date ? date.substring(0, 10) : '-';
    },
    isCheckedGroup(payload: NimsGroup) {
      return String(payload.seqno) === this.tmpMainNo;
    },
    isCheckedEquipment(payload: NimsEquipment) {
      return String(payload.seqno) === this.tmpEquipNo;
    },
    openGroupSelectModal() {
      this.$GabiaModal.show('nims-group-select-modal');
    },
    openEquipmentSelectModal() {
      this.$GabiaModal.show('nims-tech-select-modal');
    },
    hideModal(modal_id: string) {
      this.$GabiaModal.hide(modal_id);
    },
    modifyCurrntMainGroup() {
      //! 실제로 대표그룹을 바꾸는건 아님
      this.$emit('change-current-group', parseInt(this.tmpMainNo));
      this.$GabiaModal.hide('nims-group-select-modal');
    },
    setTechHelp() {
      //! 실제로 대표그룹을 바꾸는건 아님
      this.$emit('change-tech-help', parseInt(this.tmpEquipNo));
      this.$GabiaModal.hide('nims-tech-select-modal');
    },
    goModifyGroupInfo() {
      window.location.href = `${process.env.VUE_APP_MY_GABIA_URL}/billing`;
    },
    tmpChangeGroup(payload) {
      const { detail } = payload;
      this.tmpMainNo = detail;
    },
    tmpChangeEquip(payload) {
      const { detail } = payload;
      this.tmpEquipNo = detail;
    },
    changeSelectedGroup() {
      this.$GabiaModal.hide('nims-extendable-modal');
      this.$GabiaModal.hide('nims-equipments-modal');
      this.$GabiaModal.show('nims-group-select-modal');
    },
    showHasNoEquipmentsModal() {
      this.$GabiaModal.show('nims-equipments-modal');
    },
    getIdc(item: NimsEquipment) {
      return item.idc?.label || '-';
    },
    goNexStep() {
      this.$GabiaModal.hide('nims-extendable-modal');
      this.$GabiaModal.hide('nims-equipments-modal');

      if (!this.currentEquipmentSeqNo) {
        alert('기술지원 작업대상을 선택해주세요.');
        return;
      }

      if (this.mainGroup.extendable === 'N') {
        this.$GabiaModal.show('nims-extendable-modal');
        return;
      }

      if (this.package_id) {
        this.$router.push({
          name: 'apply-01',
          params: { service_type: 'nims' },
          query: { package: this.package_id, group: this.tmpMainNo, equipment_seq: String(this.currentEquipmentSeqNo) },
        });
      }
    },
  },
});
