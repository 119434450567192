
import Vue from 'vue';
import NimsGroupSelectTechnicalTemplate from '@/templates/nims/NimsGroupSelectTechnicalTemplate.vue';
import { getAutoPayInfoUseProxy, getServiceInfoUseProxy } from '@/api/gabia-proxy';
import {
  getGroupList,
  getGroupManagerList,
  getGroupInfo,
  getGroupEquipments,
  getGroupEquipmentInfo,
  getHasOldIdc,
} from '@/api/infra-renew/index';
import { NimsGroup, NimsEquipment } from '@/api/infra-renew/types';
import { PaymentInfo } from '@/api/gabia-proxy/types';
import { ServiceAdmin } from '@/api/service/types';
import { AxiosError } from 'axios';

export default Vue.extend({
  name: 'NimsGroupTechSelect',
  components: { NimsGroupSelectTechnicalTemplate },
  props: {},
  data() {
    return {
      groupInfoList: [] as NimsGroup[],
      equipmentList: [] as NimsEquipment[],
      currentEquipment: {} as NimsEquipment,
      currentEquipmentSeqNo: 0,
      serviceAdmin: { name: '', email: '', mobile: '', phone: '' } as ServiceAdmin,
      billAdmin: { name: '', email: '', mobile: '', phone: '' } as ServiceAdmin,
      mainGroupNo: 0,
      hasEquipment: 'N',
      autoPaySeqNo: 0,
      autoPayType: '',
      autoPayTypeName: '',
      payMethod: '',
      payMethodName: '',
      alias: '',
      cardNo: '',
      isShow: false,
      companyName: '',
      companyNameFull: '',
    };
  },
  computed: {
    paymentInfo(): PaymentInfo {
      return {
        alias: this.alias,
        type_name: this.autoPayTypeName,
        type_code: this.autoPayType,
        pay_method: this.payMethod,
        pay_method_name: this.payMethodName,
        card_no: this.cardNo,
        company_name: this.companyName,
        company_name_full: this.companyNameFull,
      };
    },
    userId(): string {
      return this.$store.state.userInfo?.user_id ?? '';
    },
  },
  watch: {},
  async mounted() {
    try {
      await this.getHasOldIdcFnc();
      await this.getGroupList();
      if (!this.groupInfoList.length) {
        throw new Error('no-exist-group');
      }
      this.isShow = true;
      await this.getServiceInfo();
      await this.getGroupManagerInfo();
      await this.getGroupInfoFnc();

      if (this.autoPayType === 'PA') {
        await this.getAutoPayInfo();
      }

      if (this.hasEquipment === 'Y') {
        await this.getEquipmentList();
      }

      this.$GlobalLoading.hide();
    } catch (error: unknown) {
      console.log(error);
    }
  },
  methods: {
    async getGroupInfoFnc() {
      try {
        const { data } = await getGroupInfo(this.mainGroupNo);
        console.log(data);

        this.hasEquipment = data.data.has_equipments;
      } catch (error) {
        console.warn(error);
      }
    },

    async getEquipmentList() {
      try {
        const { data } = await getGroupEquipments(this.mainGroupNo);
        console.log(data);

        if (data.data.length === 0) {
          this.equipmentList = [];
          return;
        }

        const compareDates = (a, b) => {
          // 날짜 기준으로 내림차순 정렬
          const dateA = new Date(a.main_goods_view.apply_date);
          const dateB = new Date(b.main_goods_view.apply_date);

          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        };

        this.equipmentList = data.data.sort(compareDates);
      } catch (error) {
        console.warn(error);
      }
    },

    async getHasOldIdcFnc() {
      try {
        const { data } = await getHasOldIdc(this.userId);

        if (data.has_old_idc_goods) {
          if (process.env.VUE_APP_MODE === 'development') {
            alert(
              '주의) 이 메세지는 dev 에서만 나옵니다. 기존 IDC 상품을 갖고 있는 유저라 리다이렉트 되는거고 기존 idc가 dev 환경이 따로 없어 prod로 리다이렉트 합니다. 디버깅 사항이 아닙니다. ',
            );
          }

          window.location.href = 'https://idc.gabia.com/manage/skill/skill_support_list';
          return;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getGroupList() {
      try {
        const { data } = await getGroupList();

        if (data.data.length === 0) {
          return;
        }

        const compareDates = (a, b) => {
          // 날짜 기준으로 내림차순 정렬
          const dateA = new Date(a.regist_date);
          const dateB = new Date(b.regist_date);

          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        };

        this.groupInfoList = data.data
          .filter((item) => {
            return item.group_name && item.group_expire_date !== '9999-12-31 00:00:00';
          })
          .sort(compareDates);

        this.mainGroupNo = Number(this.groupInfoList[0].seqno);
      } catch (error) {
        alert('기술지원이 가능한 상품이 없습니다. 장비 상품 신청 후 이용해주세요.');
        window.location.href = 'https://idc.gabia.com/server';

        console.log(error);
      }
    },
    async changeTechHelp(goods_seqno: number) {
      try {
        const { data } = await getGroupEquipmentInfo(this.mainGroupNo, goods_seqno);
        this.currentEquipment = data.data;
        this.currentEquipmentSeqNo = goods_seqno;
      } catch (error) {
        console.log(error);
      }
    },
    async changeCurrentGroup(group_no: number) {
      this.$GlobalLoading.show();
      this.mainGroupNo = group_no;
      this.payMethod = '';
      this.equipmentList = [];
      this.currentEquipmentSeqNo = 0;
      await this.getServiceInfo();
      await this.getGroupManagerInfo();
      await this.getGroupInfoFnc();

      if (this.autoPayType === 'PA') {
        await this.getAutoPayInfo();
      }
      if (this.hasEquipment === 'Y') {
        await this.getEquipmentList();
      }

      this.$GlobalLoading.hide();
    },
    async getAutoPayInfo() {
      try {
        const { data } = await getAutoPayInfoUseProxy({
          uri: '/gpayments/autopayservices',
          'data[service_seqno]': this.autoPaySeqNo,
        });

        if (data?.length) {
          this.alias = data[0].alias || '';
          this.cardNo = data[0].card_no || '';
          this.companyName = data[0].pg_company_name || '';
          this.companyNameFull = data[0].pg_company_name_full || '';
          this.payMethodName = data[0].pay_method_name || '';
          this.payMethod = data[0].pay_method || '';
        }
      } catch (error) {
        const errorResponse = (error as AxiosError)?.response?.data || {
          message: '불특정 에러가 발생했습니다.',
        };
        alert(`getAutoPayInfo: ${errorResponse.message}`);
      }
    },
    async getServiceInfo() {
      try {
        const { data } = await getServiceInfoUseProxy({
          uri: `/admin/service/${this.mainGroupNo}`,
        });

        this.autoPaySeqNo = data?.service.service_main_goods_list[0].service_seqno || 0;
        this.autoPayType = data?.service.service_main_goods_list[0].pay_type || '';
        this.autoPayTypeName = data?.service.service_main_goods_list[0].pay_type_name || '';
      } catch (error) {
        const errorResponse = (error as AxiosError)?.response?.data || {
          message: '불특정 에러가 발생했습니다.',
        };
        alert(`getServiceInfo: ${errorResponse.message}`);
      }
    },
    async getGroupManagerInfo() {
      try {
        const { data } = await getGroupManagerList(this.mainGroupNo);
        console.log(data);

        const billManager = data.data.filter((item) => item.represent_flag === 'Y' && item.manager_kind.code === 'B');
        const serviceManager = data.data.filter(
          (item) => item.represent_flag === 'Y' && item.manager_kind.code === 'S',
        );

        this.billAdmin = { name: '', email: '', mobile: '', phone: '' };
        this.serviceAdmin = { name: '', email: '', mobile: '', phone: '' };

        if (billManager.length) {
          this.billAdmin.name = billManager[0].manager_name;
          this.billAdmin.email = billManager[0].manager_email;
          this.billAdmin.mobile = billManager[0].manager_mobile;
          this.billAdmin.phone = billManager[0].manager_phone;
        }

        if (serviceManager.length) {
          this.serviceAdmin.name = serviceManager[0].manager_name;
          this.serviceAdmin.email = serviceManager[0].manager_email;
          this.serviceAdmin.mobile = serviceManager[0].manager_mobile;
          this.serviceAdmin.phone = serviceManager[0].manager_phone;
        }
      } catch (error) {
        const errorResponse = (error as AxiosError)?.response?.data || {
          message: '불특정 에러가 발생했습니다.',
        };
        alert(`getGroupBillManagerInfo: ${errorResponse.message}`);
      }
    },
  },
});
