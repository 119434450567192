<template>
  <div>
    <nims-group-tech-select v-if="isNimsTechStep"></nims-group-tech-select>
    <nims-group-select v-else></nims-group-select>
  </div>
</template>

<script>
import Vue from 'vue';
import NimsGroupSelect from '@/Brain/nims/NimsGroupSelect.vue';
import NimsGroupTechSelect from '@/Brain/nims/NimsGroupTechSelect.vue';
import { NIMS_TECHNICAL_GOODS } from '@/assets/data/nims/constants';

export default Vue.extend({
  name: 'GroupSelectPage',
  components: { NimsGroupSelect, NimsGroupTechSelect },
  props: {},
  data() {
    return {};
  },
  metaInfo: {
    title: '그룹 선택',
  },
  computed: {
    isNimsTechStep() {
      const package_id = this.$route.query.package;
      return !!NIMS_TECHNICAL_GOODS[package_id];
    },
  },
  watch: {},
  methods: {
    goNextStep() {
      console.log('goNextStep');
    },
  },
});
</script>

<style lang="scss" scoped></style>
