
import Vue, { PropType } from 'vue';
import { PaymentInfo } from '@/api/gabia-proxy/types';
import { ServiceAdmin } from '@/api/service/types';
import { NimsGroup } from '@/api/infra-renew/types';
import BillLayout from '@/layouts/BillLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import InfoModal from '@/components/common/InfoModal/InfoModal.vue';
import SkeletonGradient from '@/components/loading/SkeletonGradient.vue';

const getLastDayOfMonth = (year: number, month: number) => {
  const lastDay = new Date(year, month, 0).getDate();
  return lastDay;
};

export default Vue.extend({
  name: 'NimsGroupSelectTemplate',
  components: { BillLayout, FormLayout, ColumnLayout, TableLayout, InfoModal, SkeletonGradient },
  props: {
    groupInfoList: {
      type: Array as PropType<NimsGroup[]>,
      default: () => [],
    },
    billAdmin: {
      type: Object as PropType<ServiceAdmin>,
      default: () => {
        return { name: '', email: '', mobile: '', phone: '' };
      },
    },
    serviceAdmin: {
      type: Object as PropType<ServiceAdmin>,
      default: () => {
        return { name: '', email: '', mobile: '', phone: '' };
      },
    },
    paymentInfo: {
      type: Object as PropType<PaymentInfo>,
      default: () => {
        return {
          alias: '',
          type_name: '',
          type_code: '',
          pay_method: '',
          card_no: '',
          company_name: '',
          company_name_full: '',
          pay_method_name: '',
        };
      },
    },
    mainGroupNo: {
      type: Number,
      default: 0,
    },
    isHasUncompleteProducts: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formTitle,
      tmpMainNo: '',
      package_id: '',
      goods_id: '',
      btnHeight: '35px',
      infoText: '140px',
    };
  },
  computed: {
    mainGroup(): NimsGroup {
      return this.groupInfoList.filter((item) => Number(item.seqno) === this.mainGroupNo)[0] || {};
    },
    isDataLoaded(): boolean {
      return this.mainGroupNo !== 0;
    },
    mainGroupChargeType(): string {
      return this.mainGroup.service.pay_type.code === 'PA' ? '정기결제' : '일반결제';
    },
  },
  watch: {
    mainGroupNo(newValue) {
      this.tmpMainNo = String(newValue);
    },
  },
  mounted() {
    this.package_id = this.$route.query.package as string;
    this.goods_id = this.$route.query.goods_id as string;
  },
  methods: {
    getDate(date: string) {
      return date ? date.substring(0, 10) : '-';
    },
    isCheckedGroup(payload: NimsGroup) {
      return String(payload.seqno) === this.tmpMainNo;
    },
    openGroupSelectModal() {
      this.$GabiaModal.show('nims-group-select-modal');
    },
    hideGroupSelectModal() {
      this.$GabiaModal.hide('nims-group-select-modal');
    },
    hideUncompleteModal() {
      this.$GabiaModal.hide('nims-uncomplete-product-modal');
    },
    modifyCurrntMainGroup() {
      //! 실제로 대표그룹을 바꾸는건 아님
      this.$emit('change-current-group', parseInt(this.tmpMainNo));
      this.$GabiaModal.hide('nims-group-select-modal');
    },
    goNoCreateGroupFirstStep() {
      this.$emit('go-no-create-group');
    },
    goModifyGroupInfo() {
      window.location.href = `${process.env.VUE_APP_MY_GABIA_URL}/billing`;
    },
    tmpChangeGroup(payload) {
      const { detail } = payload;
      this.tmpMainNo = detail;
    },
    changeSelectedGroup() {
      this.$GabiaModal.hide('nims-extendable-modal');
      this.$GabiaModal.show('nims-group-select-modal');
    },
    goNexStep() {
      if (this.mainGroup.extendable === 'N') {
        this.$GabiaModal.show('nims-extendable-modal');
        return;
      }

      if (this.isHasUncompleteProducts && this.mainGroup.group_expire_date === '9999-12-31 00:00:00') {
        this.$GabiaModal.show('nims-uncomplete-product-modal');
        return;
      }

      if (this.mainGroup.group_expire_date !== '9999-12-31 00:00:00') {
        const [year, month, day] = this.mainGroup.group_expire_date.split('-').map((num) => parseInt(num));

        const compareDay = getLastDayOfMonth(year, month);

        if (day !== compareDay) {
          alert('만기일이 말일인 그룹만 상품 신청이 가능합니다');
          return;
        }
      }

      if (this.package_id) {
        this.$router.push({
          name: 'apply-01',
          params: { service_type: 'nims' },
          query: { package: this.package_id, group: this.tmpMainNo },
        });
      } else {
        this.$router.push({
          name: 'apply-01',
          params: { service_type: 'nims' },
          query: { goods_id: this.goods_id, group: this.tmpMainNo },
        });
      }
    },
  },
});
