
import Vue from 'vue';
import NimsGroupSelectTemplate from '@/templates/nims/NimsGroupSelectTemplate.vue';
import { getAutoPayInfoUseProxy, getServiceInfoUseProxy } from '@/api/gabia-proxy';
import { getGroupList, getGroupManagerList, getGroupServiceValidate, getHasOldIdc } from '@/api/infra-renew/index';
import { NimsGroup } from '@/api/infra-renew/types';
import { PaymentInfo } from '@/api/gabia-proxy/types';
import { ServiceAdmin } from '@/api/service/types';
import { AxiosError } from 'axios';

export default Vue.extend({
  name: 'NimsGroupSelect',
  components: { NimsGroupSelectTemplate },
  props: {},
  data() {
    return {
      groupInfoList: [] as NimsGroup[],
      serviceAdmin: { name: '', email: '', mobile: '', phone: '' } as ServiceAdmin,
      billAdmin: { name: '', email: '', mobile: '', phone: '' } as ServiceAdmin,
      mainGroupNo: 0,
      autoPaySeqNo: 0,
      autoPayType: '',
      autoPayTypeName: '',
      payMethod: '',
      payMethodName: '',
      alias: '',
      cardNo: '',
      isShow: false,
      companyName: '',
      companyNameFull: '',
      isHasUncompleteProducts: false,
    };
  },
  computed: {
    paymentInfo(): PaymentInfo {
      return {
        alias: this.alias,
        type_name: this.autoPayTypeName,
        type_code: this.autoPayType,
        pay_method: this.payMethod,
        pay_method_name: this.payMethodName,
        card_no: this.cardNo,
        company_name: this.companyName,
        company_name_full: this.companyNameFull,
      };
    },
    userId(): string {
      return this.$store.state.userInfo?.user_id ?? '';
    },
  },
  watch: {},
  async mounted() {
    try {
      await this.getHasOldIdcFnc();
      await this.getGroupList();
      if (!this.groupInfoList.length) {
        throw new Error('no-exist-group');
      }
      this.isShow = true;
      await this.getServiceInfo();
      await this.getGroupManagerInfo();
      if (this.autoPayType === 'PA') {
        await this.getAutoPayInfo();
      }
      this.$GlobalLoading.hide();
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (error.message === 'no-exist-group') {
          this.goNoGroupSelectedFirstStep();
        }
      }
    }
  },
  methods: {
    goNoGroupSelectedFirstStep() {
      const queryParams = this.$route.query;
      if (queryParams.package) {
        this.$router.push({
          name: 'apply-01',
          params: { service_type: 'nims' },
          query: { package: queryParams.package },
        });
      } else {
        this.$router.push({
          name: 'apply-01',
          params: { service_type: 'nims' },
          query: { goods_id: queryParams.goods_id },
        });
      }
    },
    async getHasOldIdcFnc() {
      const queryParams = this.$route.query;

      const package_id = queryParams?.package || false;
      const goods_id = queryParams?.goods_id || false;

      console.log(package_id, goods_id);

      try {
        const { data } = await getHasOldIdc(this.userId);

        if (data.has_old_idc_goods) {
          if (process.env.VUE_APP_MODE === 'development') {
            alert(
              '주의) 이 메세지는 dev 에서만 나옵니다. 기존 IDC 상품을 갖고 있는 유저라 리다이렉트 되는거고 기존 idc가 dev 환경이 따로 없어 prod로 리다이렉트 합니다. 디버깅 사항이 아닙니다. ',
            );
          }

          const redirect_url = package_id
            ? `https://idc.gabia.com/goods/application/server_step1?id=${package_id}`
            : 'https://idc.gabia.com/manage/resources/addition_service';

          window.location.href = redirect_url;
          return;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getGroupList() {
      try {
        const { data } = await getGroupList();

        if (data.data.length === 0) {
          return;
        }

        const compareDates = (a, b) => {
          // 날짜 기준으로 내림차순 정렬
          const dateA = new Date(a.regist_date);
          const dateB = new Date(b.regist_date);

          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        };

        this.groupInfoList = data.data
          .filter((item) => {
            return item.group_name && item.group_expire_date;
          })
          .sort(compareDates);

        this.mainGroupNo = Number(this.groupInfoList[0].seqno);
      } catch (error) {
        console.log(error);
      }
    },
    async changeCurrentGroup(group_no: number) {
      this.mainGroupNo = group_no;
      this.payMethod = '';
      await this.getServiceInfo();
      await this.getGroupManagerInfo();
      if (this.autoPayType === 'PA') {
        await this.getAutoPayInfo();
      }
    },
    async getAutoPayInfo() {
      try {
        const { data } = await getAutoPayInfoUseProxy({
          uri: '/gpayments/autopayservices',
          'data[service_seqno]': this.autoPaySeqNo,
        });

        if (data?.length) {
          this.alias = data[0].alias || '';
          this.cardNo = data[0].card_no || '';
          this.companyName = data[0].pg_company_name || '';
          this.companyNameFull = data[0].pg_company_name_full || '';
          this.payMethodName = data[0].pay_method_name || '';
          this.payMethod = data[0].pay_method || '';
        }
      } catch (error) {
        const errorResponse = (error as AxiosError)?.response?.data || {
          message: '불특정 에러가 발생했습니다.',
        };
        alert(`getAutoPayInfo: ${errorResponse.message}`);
      }
    },
    async getServiceInfo() {
      try {
        const { data } = await getServiceInfoUseProxy({
          uri: `/admin/service/${this.mainGroupNo}`,
        });

        this.autoPaySeqNo = data?.service.service_main_goods_list[0].service_seqno || 0;
        this.autoPayType = data?.service.service_main_goods_list[0].pay_type || '';
        this.autoPayTypeName = data?.service.service_main_goods_list[0].pay_type_name || '';
        this.isHasUncompleteProducts = false;

        const { data: vaildateData } = await getGroupServiceValidate(this.mainGroupNo, 'S');

        if (vaildateData.data) {
          this.isHasUncompleteProducts = vaildateData.data.result === 'Y' ? false : true;
        }
      } catch (error) {
        const errorResponse = (error as AxiosError)?.response?.data || {
          message: '불특정 에러가 발생했습니다.',
        };
        alert(`getServiceInfo: ${errorResponse.message}`);
      }
    },
    async getGroupManagerInfo() {
      try {
        const { data } = await getGroupManagerList(this.mainGroupNo);

        const billManager = data.data.filter((item) => item.represent_flag === 'Y' && item.manager_kind.code === 'B');
        const serviceManager = data.data.filter(
          (item) => item.represent_flag === 'Y' && item.manager_kind.code === 'S',
        );

        this.billAdmin = { name: '', email: '', mobile: '', phone: '' };
        this.serviceAdmin = { name: '', email: '', mobile: '', phone: '' };

        if (billManager.length) {
          this.billAdmin.name = billManager[0].manager_name;
          this.billAdmin.email = billManager[0].manager_email;
          this.billAdmin.mobile = billManager[0].manager_mobile;
          this.billAdmin.phone = billManager[0].manager_phone;
        }

        if (serviceManager.length) {
          this.serviceAdmin.name = serviceManager[0].manager_name;
          this.serviceAdmin.email = serviceManager[0].manager_email;
          this.serviceAdmin.mobile = serviceManager[0].manager_mobile;
          this.serviceAdmin.phone = serviceManager[0].manager_phone;
        }
      } catch (error) {
        const errorResponse = (error as AxiosError)?.response?.data || {
          message: '불특정 에러가 발생했습니다.',
        };
        alert(`getGroupBillManagerInfo: ${errorResponse.message}`);
      }
    },
  },
});
